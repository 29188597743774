import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { OTHER_CELL_CULTURE, OTHER_CELL_CULTURE_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'other-cell-culture',
  templateUrl: './other-cell-culture.component.html',
  styleUrls: ['./other-cell-culture.component.css']
})
export class OtherCellCultureComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = OTHER_CELL_CULTURE;
    this.titleService.setTitle(OTHER_CELL_CULTURE_TITLE);
  }

}
