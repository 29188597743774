import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import SharedService from '../../shared/shared.service';
import { BaseComponent } from '../base.component';
import { MISSION, MISSION_TITLE } from '../../shared/constants/pages.constants';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent extends BaseComponent  {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = MISSION;
    this.titleService.setTitle(MISSION_TITLE);

  }

}
