import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import SharedService from '../../shared/shared.service';
import { BaseComponent } from '../base.component';
import { POLICIES, POLICIES_TITLE } from '../../shared/constants/pages.constants';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = POLICIES;
    this.titleService.setTitle(POLICIES_TITLE);

  }

}
