<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents"></accordion>
    </div>
  </div>




  <!--<div class="m-t-sm m-b-sm container">
    <table>
      <tr><th>Service</th><th>Description</th><th>Timing</th></tr>
      <tr>
        <td>hPSC Culture on Feeder Layer</td>
        <td>Hands-on training to perform the procedures involved in hPSC culture, specifically on MEF feeder layers. This includes preparing plates to contain MEF feeder layers, thawing, passaging and freezing hPSC cultures as well as selecting and manually picking hiPSC colonies from culture dish. We also offer training for characterization and quality control techniques specific to this service.</td>
        <td>One hour per day for one week</td>
      </tr>
      <tr><td>hPSC Culture on Feeder Free Condition</td><td>Hands-on training to perform the procedures involved in hPSC culture, specifically on feeder free conditions. This includes coating plates with basement membrane matrix Matrigel, thawing, passaging and freezing hPSC cultures. We also offer training for characterization and quality control techniques specific to this service.</td><td>One hour per day for one week</td></tr>
      <tr><td>Differentiation of hPSC</td><td>Hands-on training to perform differentiation(s) of hPSC cultures, which include neuroectoderm, mesoendoderm/cardiac progenitors, and definitive endoderm lineages. We also offer training for characterization and quality control techniques specific to this service.</td><td>One hour per day for one week</td></tr>
      <tr><td>Electroporation</td><td>Training using the Lonza 4D Nucleofector to successfully learn and perform the process of electroporation, critical for gene editing of hPSC cultures.</td><td>One to two hours</td></tr>
    </table>

  </div>-->
</section>
