import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { HPSC_CULTURE, HPSC_CULTURE_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hpsc-culture',
  templateUrl: './hpsc-culture.component.html',
  styleUrls: ['./hpsc-culture.component.css']
})
export class HpscCultureComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = HPSC_CULTURE;
    this.titleService.setTitle(HPSC_CULTURE_TITLE);

  }

}
