import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { GENE_EDITING_RELATED, GENE_EDITING_RELATED_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gene-editing-related',
  templateUrl: './gene-editing-related.component.html',
  styleUrls: ['./gene-editing-related.component.css']
})
export class GeneEditingRelatedComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = GENE_EDITING_RELATED;
    this.titleService.setTitle(GENE_EDITING_RELATED_TITLE);
  }

}
