<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
        </div>
      </div>

 
      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
        <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
      </div>


    </div>
  </section>
</section>




<ng-template #contentTemplate let-content="content">
    <div class="column is-12">
      <div class="box" style="height:500px">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(content.url)" frameborder="0" style="border:0; width:100%" height="450" allowfullscreen=""></iframe>
      </div>
    </div>
    <div class="column">
      <div class="box" style="height:100%">
        <div [innerHTML]="content.description | safeHtml"></div>
      </div>
    </div>
</ng-template>
