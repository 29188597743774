<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel" [fourthLevel]="fourthLevel"></accordion>
    </div>

    <br />
    <div class="m-t-b-sm m-t-sm"><span style="font-size:14px">Dr. Zhou and the Stem Cell Research Facility welcomes collaboration with investigators of all levels from SKI, the Tri-institutes and other institutions.</span></div>
  </div>
  
</section>
