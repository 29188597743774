import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import SharedService from '../../shared/shared.service';
import { BaseComponent } from '../base.component';
import { PRUEBA, PRUEBA_TITLE } from '../../shared/constants/pages.constants';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.css']
})
export class PruebaComponent extends BaseComponent implements OnInit {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = PRUEBA;
    this.titleService.setTitle(PRUEBA_TITLE);
  }

  ngOnInit() {
    this.getPage();
    this.getContent('datetime:DESC');
  }
}
