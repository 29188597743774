<!--https://gsr.dev/material2-carousel/-->
<!--https://github.com/gbrlsnchs/material2-carousel-->
<div class="wrapper">
  <div class="columns mb-0">
    <div class="column is-full p-0">
      <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="6000"
                    color="accent"
                    maxWidth="auto"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="false"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    [maintainAspectRatio]="true"
                    [proportion]="29"
                    orientation="ltr">
        <mat-carousel-slide #matCarouselSlide
                            *ngFor="let slide of sliders; let i = index"
                            overlayColor="#00000040"
                            [hideOverlay]="true">
          <a [attr.href]="slide.url" target="_blank" *ngIf="slide.is_external">
            <!-- <img [src]="_cmsApiUrl + slide.image.url" style="width:100%"> -->
            <img [src]="slide.image.url" style="width:100%">
          </a>
          <a [routerLink]="slide.url" *ngIf="!slide.is_external">
            <!-- <img [src]="_cmsApiUrl + slide.image.url" style="width:100%"> -->
            <img [src]="slide.image.url" style="width:100%">
          </a>
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>

  <div class="columns m-2">
    <div class="column" *ngIf="news" style="width:33%">
      <div class="box" style="height:100%">
        <h3 class="title is-4">
          {{news.title}}
        </h3>
        <div [innerHTML]="news.description | safeHtml">
        </div>
      </div>
    </div>
    <div class="column m-3" *ngIf="mission" style="width:33%">
      <div class="box mb-0" style="height:100%">
        <h3 class="title is-4">
          {{mission.title}}
        </h3>
        <div [innerHTML]="mission.description | safeHtml">
        </div>
      </div>
    </div>

    <div class="column m-3" *ngIf="team">
      <div class="box mb-0" style="height:100%">
        <h3 class="title is-4">
          {{team.title}}
        </h3>
        <div [innerHTML]="team.description | safeHtml">
        </div>
      </div>
    </div>

  </div>
  <div class="columns mt-6 mb-6 is-mobile is-centered">
    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/people" class="button is-fullwidth is-link">
          About
        </a>
      </div>
    </div>
    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/people" class="button is-fullwidth is-link">
          Contact
        </a>
      </div>
    </div>
    <div class="column is-one-quarter">
      <div class="buttons are-large">
        <a routerLink="/news-and-events" class="button is-fullwidth is-link">
          News
        </a>
      </div>
    </div>
  </div>
</div>

<hr />
<div class="box" style="width: 74%; margin: auto; ">
  <div class="tabs is-toggle is-fullwidth">
    <ul class="m-0">
      <li ng-reflect-ng-class="[object Object]" class="is-active ng-star-inserted"><a><span class="icon is-small"><i aria-hidden="true" class="fas fa-image"></i></span><span>SKI Stem Cell Research Facility</span></a></li>
      <!--bindings={
        "ng-reflect-ng-for-of": "[object Object]"
      }-->
    </ul>
  </div><div>
    <section class="ml-2 mr-2 tab-content hide show ng-star-inserted" ng-reflect-ng-class="[object Object]">
      <div class="ng-star-inserted"><div class="column is-12 ng-star-inserted"><div class="box" style="height: 500px;"><iframe frameborder="0" height="450" allowfullscreen="" style="border: 0; width: 100%;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.919966674542!2d-73.9592560490551!3d40.76378497922496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258c2562f60b9%3A0x416b6e2eb957971e!2sRockefeller+Research+Laboratories!5e0!3m2!1sen!2sus!4v1519002910804"></iframe></div></div><div class="column ng-star-inserted"><div class="box" style="height: 100%;"><div><p style="margin-left:0px;">Rockefeller Research Laboratories</p><p style="margin-left:0px;">430 East 67th Street, RRL961</p><p style="margin-left:0px;">New York, NY</p><p style="margin-left:0px;">10065</p><p style="margin-left:0px;">Tel: 212-639-3969</p></div></div></div><!--ng-container--></div>

      <div>
      </div>
    </section>
  </div>
</div>
<br /><br />
