<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
        </div>
      </div>


      <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0">
        <div class="box" *ngFor="let content of contents" [id]="content.content_uid">

          <div *ngIf="content.order%2 ===0">
            <article class="media">

              <div class="media-left">
                <figure class="image is-512x512">
                  <!-- <img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                  <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
                </figure>
              </div>

              <div class="media-content">
                <div class="content">
                  <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                  <span class="ml-1">{{content.sub_title}}</span>
                  <br>
                  <div [innerHTML]="content.description | safeHtml"></div>
                </div>
              </div>

            </article>
          </div>

          <div *ngIf="content.order%2 ===1">
            <article class="media">

              <div class="media-content">
                <div class="content">
                  <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                  <span class="ml-1">{{content.sub_title}}</span>
                  <br>
                  <div [innerHTML]="content.description | safeHtml"></div>
                </div>
              </div>

              <div class="media-left">
                <figure class="image is-512x512">
                  <!-- <img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                  <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].url" [alt]="content.title">
                </figure>
              </div>




            </article>
          </div>

        </div>
      </div>

    </div>
</section>
</section>
