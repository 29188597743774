
export const HOME = 0;
export const HESC = 11;
export const HESC_LINE = 111;
export const REPORTER_OTHER_GENE_ENGINEERED_LINES = 12;
export const HPSC_DERIVED_CELLS = 13;
export const ASAP_LINES = 17;

export const INSTRUMENTS = 10;
export const PROJECTS = 14;
export const PROTOCOLS = 15;
export const NEWS_AND_EVENTS = 16;

export const MISSION = 21;
export const PEOPLE = 22;
export const LOCATION = 23;
export const POLICIES = 24;
export const FAQS = 25;

export const CELL_REPROGRAMING = 31;
export const GENE_EDITING = 32;
export const TRI_LINEAGE_DIFFERENTIATION = 33;
export const GENERATION = 34;
export const TRAINING = 35;

export const RESEARCH_TEAM = 40;
export const SEARCH = 48;

export const HPSC_CULTURE = 51;
export const HPSC_DIFFERENTIATION = 52;
export const OTHER_CELL_CULTURE = 53;
export const GENE_EDITING_RELATED = 54;
export const IMMUNOFLUORESCENCE = 55;

export const PRUEBA = 99;


export const ASAP_LINES_TITLE = "ASAP Lines";
export const HESC_TITLE = "hESC/hiPSC Lines";
export const REPORTER_OTHER_GENE_ENGINEERED_LINES_TITLE = "Reporter/Other Gene Engineered Lines";
export const HPSC_DERIVED_CELLS_TITLE = "hPSC-derived Cells";

export const INSTRUMENTS_TITLE = "Instruments";
export const PROJECTS_TITLE = "Projects";
export const PROTOCOLS_TITLE = "Protocols";
export const NEWS_AND_EVENTS_TITLE = "News And Events"

export const CELL_REPROGRAMING_TITLE = "Cell Reprograming";
export const GENE_EDITING_TITLE = "Gene Editing in hPSCs";
export const TRI_LINEAGE_DIFFERENTIATION_TITLE = "Tri-lineage Differentiation";
export const GENERATION_TITLE = "Generation of Fibroblasts from Human Tissue";
export const TRAINING_TITLE = "Training";

export const MISSION_TITLE = "Mission";
export const PEOPLE_TITLE = "People";
export const LOCATION_TITLE = "Location";
export const POLICIES_TITLE = "Policies";
export const FAQS_TITLE = "FAQS";

export const RESEARCH_TEAM_TITLE = "Stem Cell Research Team";


export const HPSC_CULTURE_TITLE = "hPSC Culture";
export const HPSC_DIFFERENTIATION_TITLE = "hPSC Differentiation";
export const OTHER_CELL_CULTURE_TITLE = "Other Cell Culture";
export const GENE_EDITING_RELATED_TITLE = "Gene Editing Related";
export const IMMUNOFLUORESCENCE_TITLE = "Immunofluorescence";
export const SEARCH_TITLE = "Search";
export const PRUEBA_TITLE = "TEST";
