<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <!--<div class="m-b-sm m-t-sm">
    <accordion [contents]="contents"></accordion>
  </div>-->
    <!--<div class="m-b-sm m-t-sm">
      <accordion [contents]="contents" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>
    </div>-->


    <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0">
      <div class="box" *ngFor="let content of contents" [id]="content.content_uid">
        <article class="media">

          <div class="media-content">
            <div class="content">
              <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
              <span class="ml-1">{{content.sub_title}}</span>
              <br>
              <div [innerHTML]="content.description | safeHtml"></div>
            </div>
            <!--<figure class="image is-256x256">
              <img *ngIf="content.images.length" class="" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title">
            </figure>-->

          </div>
        </article>
      </div>
    </div>


  </div>
</section>
