<div>
  <div *ngFor="let content of contents">
    <div *ngIf="loadContentTemplate">
      <ng-container [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{content:content}"></ng-container>
    </div>
    <div *ngIf="!loadContentTemplate">
      <div [innerHTML]="content.description | safeHtml">
      </div>
    </div>
  </div>
</div>
