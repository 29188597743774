import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { GENE_EDITING, GENE_EDITING_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gene-editing',
  templateUrl: './gene-editing.component.html',
  styleUrls: ['./gene-editing.component.css']
})
export class GeneEditingComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = GENE_EDITING;
    this.titleService.setTitle(GENE_EDITING_TITLE);

  }

}
