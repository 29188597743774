import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { CELL_REPROGRAMING, CELL_REPROGRAMING_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cell-reprograming',
  templateUrl: './cell-reprograming.component.html',
  styleUrls: ['./cell-reprograming.component.css']
})
export class CellReprogramingComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = CELL_REPROGRAMING;
    this.titleService.setTitle(CELL_REPROGRAMING_TITLE);

  }

}
