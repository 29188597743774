<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents"></accordion>
    </div>
  </div>

  <div class="column m-3" *ngIf="lines">
    <div class="box mb-0" style="height:100%">
      <!--<h3 class="title is-4">
        {{lines.title}}
      </h3>-->
      <div [innerHTML]="lines.description | safeHtml">
      </div>
    </div>
  </div>
</section>
