import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import SharedService from '../../shared/shared.service';
import { BaseComponent } from '../base.component';
import { PEOPLE, PEOPLE_TITLE } from '../../shared/constants/pages.constants';

import {
  IconDefinition,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent extends BaseComponent {

  public faPlay: IconDefinition = faPlayCircle;

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = PEOPLE;
    this.titleService.setTitle(PEOPLE_TITLE);

  }

}
