import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { ASAP_LINES, ASAP_LINES_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-asap-lines',
  templateUrl: './asap-lines.component.html',
  styleUrls: ['./asap-lines.component.css']
})
export class AsapLinesComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = ASAP_LINES;
    this.titleService.setTitle(ASAP_LINES_TITLE);

  }


}
