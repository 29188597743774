import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { REPORTER_OTHER_GENE_ENGINEERED_LINES, REPORTER_OTHER_GENE_ENGINEERED_LINES_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-reporter-other-gene-engineered-lines',
  templateUrl: './reporter-other-gene-engineered-lines.component.html',
  styleUrls: ['./reporter-other-gene-engineered-lines.component.css']
})
export class ReporterOtherGeneEngineeredLinesComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = REPORTER_OTHER_GENE_ENGINEERED_LINES;
    this.titleService.setTitle(REPORTER_OTHER_GENE_ENGINEERED_LINES_TITLE);

  }

}
