import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { IMMUNOFLUORESCENCE, IMMUNOFLUORESCENCE_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-immunofluorescence',
  templateUrl: './immunofluorescence.component.html',
  styleUrls: ['./immunofluorescence.component.css']
})
export class ImmunofluorescenceComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = IMMUNOFLUORESCENCE;
    this.titleService.setTitle(IMMUNOFLUORESCENCE_TITLE);
  }

}
