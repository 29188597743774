import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { HESC_LINE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hesc-line',
  templateUrl: './hesc-line.component.html',
  styleUrls: ['./hesc-line.component.css']
})
export class HescLineComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = HESC_LINE;
    this.titleService.setTitle("--");

  }

}
