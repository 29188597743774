import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { HPSC_DIFFERENTIATION, HPSC_DIFFERENTIATION_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hpsc-differentiation',
  templateUrl: './hpsc-differentiation.component.html',
  styleUrls: ['./hpsc-differentiation.component.css']
})
export class HpscDifferentiationComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = HPSC_DIFFERENTIATION;
    this.titleService.setTitle(HPSC_DIFFERENTIATION_TITLE);
  }

}
