import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { NEWS_AND_EVENTS, NEWS_AND_EVENTS_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.css']
})
export class NewsAndEventsComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = NEWS_AND_EVENTS;
    this.titleService.setTitle(NEWS_AND_EVENTS_TITLE);

  }

}
