import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  LocationComponent,
  MissionComponent,
  PeopleComponent,
  PoliciesComponent,
  TrainingComponent,
  FaqsComponent,
  InstrumentsComponent,
  HescHipscLinesComponent,
  ReporterOtherGeneEngineeredLinesComponent,
  HpscDerivedCellsComponent,
  ProjectsComponent,
  ProtocolsComponent,
  NewsAndEventsComponent,
  CellReprogramingComponent,
  GenerationComponent,
  GeneEditingComponent,
  HescLineComponent,
  TriLineageDifferentiationComponent
} from './component.index';

import { ComponentRoutes } from './component.routes';
import { PruebaComponent } from './prueba/prueba.component';
import { HpscCultureComponent } from './hpsc-culture/hpsc-culture.component';
import { HpscDifferentiationComponent } from './hpsc-differentiation/hpsc-differentiation.component';
import { OtherCellCultureComponent } from './other-cell-culture/other-cell-culture.component';
import { GeneEditingRelatedComponent } from './gene-editing-related/gene-editing-related.component';
import { ImmunofluorescenceComponent } from './immunofluorescence/immunofluorescence.component';
import { SearchComponent } from './search/search.component';
import { AsapLinesComponent } from './asap-lines/asap-lines.component';



@NgModule({
  declarations: [
LocationComponent,
MissionComponent,
PeopleComponent,
PoliciesComponent,
TrainingComponent,
FaqsComponent,
InstrumentsComponent,
HescHipscLinesComponent,
ReporterOtherGeneEngineeredLinesComponent,
HpscDerivedCellsComponent,
ProjectsComponent,
ProtocolsComponent,
NewsAndEventsComponent,
CellReprogramingComponent,
GeneEditingComponent,
TriLineageDifferentiationComponent,
GenerationComponent,
HescLineComponent,
PruebaComponent,
HpscCultureComponent,
HpscDifferentiationComponent,
OtherCellCultureComponent,
GeneEditingRelatedComponent,
ImmunofluorescenceComponent,
SearchComponent,
AsapLinesComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forChild(ComponentRoutes),
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule
  ]
})
export class ComponentModule { }
