import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { HESC, HESC_LINE, HESC_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IPage } from '../../shared/model/model.index';

@Component({
  selector: 'app-hesc-hipsc-lines',
  templateUrl: './hesc-hipsc-lines.component.html',
  styleUrls: ['./hesc-hipsc-lines.component.css']
})
export class HescHipscLinesComponent extends BaseComponent {

  _sharedService: SharedService;
  lines: IPage;

  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {

    super(sharedService, route, router);

    this.uid = HESC;
    this.titleService.setTitle(HESC_TITLE);
    this._sharedService = sharedService;

    //this.getData();

  }
    //getData(): void {
    //  this._sharedService.getPageByUID(HESC_LINE).subscribe(p => {
    //    this.lines = p;
    //  });
    //}

}
